import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from './../../services/storage.service';
import { AppConstants } from './../../config/app-constants';

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss'],
})
export class MemberListComponent implements OnInit {
  @Input() items: any;
  image_url:string;
  authUser;
  isLoggedin = false;
  nav_isLoggedin='';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService, 
  ) { }

  ngOnInit() {
    this.image_url = environment.apiUrl + 'assets/images/members/';
    this.storageService.get(AppConstants.AUTH).then(res => {
      if(res){
        this.authUser = res;
        this.isLoggedin = this.authUser.id ? true : false;
        this.nav_isLoggedin = this.isLoggedin == true ? '/home' : '';
      }
      //console.log('res_list', res);
    });

    //console.log('items', typeof(this.items));
  }

  getNewPerson(id: number) {
    this.router.navigate([this.nav_isLoggedin + '/members/'+ id]);
  }

}
