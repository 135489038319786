import { Component, OnInit } from '@angular/core';

import { AppConstants } from './../../config/app-constants';
import { StorageService } from './../../services/storage.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public authUser;
  nav_isLoggedin:string='';
  isLoggedin:Boolean = false;
  person_edit:Boolean = false;
  
  constructor(
    private storageService: StorageService, 
  ) { }

  ngOnInit() {
    this.storageService.get(AppConstants.AUTH).then(res => {
      if(res){
        this.authUser = res;
        this.isLoggedin = this.authUser.id ? true : false;
        this.nav_isLoggedin = this.isLoggedin == true ? '/home' : '';
        console.log('isLoggedin', this.isLoggedin, 'nav_isLoggedin',this. nav_isLoggedin, 'res', res);
      }
    });
  }

}
