export class AppConstants {
  public static readonly AUTH = 'userData';
  public static readonly AllowIds = 'allow_member_ids';

  public static readonly Person = 'personData';
  public static readonly PersonDetails = 'personDetailsData';
  public static readonly PersonChildren = 'personChildrenData';
  public static readonly Personspouses = 'PersonspousesData';
  
}
