import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./index/index.module').then( m => m.IndexPageModule)
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'members',
    loadChildren: () => import('./pages/members/members.module').then( m => m.MembersPageModule)
  },
  {
    path: 'edit-member',
    loadChildren: () => import('./pages/edit-member/edit-member.module').then( m => m.EditMemberPageModule)
  },
  {
    path: 'edit-children',
    loadChildren: () => import('./pages/edit-children/edit-children.module').then( m => m.EditChildrenPageModule)
  },
  {
    path: 'edit-spouse',
    loadChildren: () => import('./pages/edit-spouse/edit-spouse.module').then( m => m.EditSpousePageModule)
  },
  {
    path: 'edit-parents',
    loadChildren: () => import('./pages/edit-parents/edit-parents.module').then( m => m.EditParentsPageModule)
  },
  {
    path: 'home-page',
    loadChildren: () => import('./pages/home-page/home-page.module').then( m => m.HomePagePageModule)
  },
  {
    path: 'events',
    loadChildren: () => import('./pages/events/events.module').then( m => m.EventsPageModule)
  }

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
