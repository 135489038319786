import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';
import { TimeAgoPipe } from 'time-ago-pipe';
import { FeedCardComponent } from './feed-card/feed-card.component';
import { FeedUpdateComponent } from './feed-update/feed-update.component';
import { SlidesComponent } from './slides/slides.component';
import { StartButtonComponent } from './start-button/start-button.component';
import { TimeagoComponent } from './timeago/timeago.component';
import { MemberListComponent } from './member-list/member-list.component';
import { EditChildListComponent } from './edit-child-list/edit-child-list.component';
import { EditSpouseListComponent } from './edit-spouse-list/edit-spouse-list.component';
import { EditParentListComponent } from './edit-parent-list/edit-parent-list.component';
import { HeaderComponent } from './header/header.component';
@NgModule({
  declarations: [
    SlidesComponent,
    StartButtonComponent,
    FeedCardComponent,
    FeedUpdateComponent,
    TimeAgoPipe,
    TimeagoComponent,
    MemberListComponent,
    EditChildListComponent,
    EditSpouseListComponent,
    EditParentListComponent,
    HeaderComponent
  ],
  exports: [
    SlidesComponent,
    StartButtonComponent,
    FeedCardComponent,
    FeedUpdateComponent,
    TimeagoComponent,
    MemberListComponent,
    EditChildListComponent,
    EditSpouseListComponent,
    EditParentListComponent,
    HeaderComponent
  ],
  imports: [CommonModule, FormsModule, RouterModule, IonicModule]
})
export class ComponentsModule {}
