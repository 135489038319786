import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import{ LoadingService} from '../../services/loading.service';

import { AppConstants } from './../../config/app-constants';
import { environment } from '../../../environments/environment';
import { StorageService } from './../../services/storage.service';
import { PersonService } from './../../services/person.service';

@Component({
  selector: 'app-edit-parent-list',
  templateUrl: './edit-parent-list.component.html',
  styleUrls: ['./edit-parent-list.component.scss'],
})
export class EditParentListComponent implements OnInit {

  @Input() person: any;
  image_url:string;
  authUser;
  isLoggedin = false;
  nav_isLoggedin='';
  

  constructor(
    public alertCtrl: AlertController,
    public modalController: ModalController,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService, 
    private personService: PersonService,
  ) { }

  ngOnInit() {
    this.image_url = environment.apiUrl + 'assets/images/members/';

    //Collect Person details in local storage (or) database
    this.storageService.get(AppConstants.Person).then((person_res:any) => {
      if (person_res != null) {
          this.person = person_res;
          console.log('person', this.person);
      }
    });
  }



  editMember(id: number) {
    this.router.navigate(['/home/edit-member/'+ id]);
    /* 
    this.person_id = id;
    console.warn('person_id', this.person_id);
    this.get_person(this.person_id);
    this.location.replaceState('/home/edit-member/'+ id); 
    */ 
  }

  alertDeleteFather(id, name){

  }

  alertDeleteMother(id, name){

  }

}
