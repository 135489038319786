import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AlertController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import{ LoadingService} from '../../services/loading.service';

import { AppConstants } from './../../config/app-constants';
import { environment } from '../../../environments/environment';
import { StorageService } from './../../services/storage.service';
import { PersonService } from './../../services/person.service';

@Component({
  selector: 'app-edit-spouse-list',
  templateUrl: './edit-spouse-list.component.html',
  styleUrls: ['./edit-spouse-list.component.scss'],
})
export class EditSpouseListComponent implements OnInit {
  @Input() items: any;
  image_url:string;
  authUser;
  isLoggedin = false;
  nav_isLoggedin='';
  person;
  constructor(
    public alertCtrl: AlertController,
    public modalController: ModalController,
    public loading: LoadingService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService, 
    private personService: PersonService,
  ) { }

  ngOnInit() {
    this.image_url = environment.apiUrl + 'assets/images/members/';

    //Collect Person details in local storage (or) database
    this.storageService.get(AppConstants.Person).then((person_res:any) => {
      if (person_res != null) {
          this.person = person_res;
          //console.log('person', person);
      }
    });
  }

  ionViewWillEnter() {
    this.get_spouse();
  }

  editMember(id: number) {
    this.router.navigate(['/home/edit-member/'+ id]);
  }

  async get_person(){
    
    this.personService.getMember(this.person.id).subscribe(data => {
      this.person = data.current_member;
      this.storageService.store(AppConstants.Person, this.person);
      console.log('spouse_list_person', this.person);
      this.get_spouse();
    },
    error => {
      console.log('spouse_person',error);
      //this.loading.dismiss();
    });
  }

  get_spouse(){
    this.personService.get_spouse(this.person).subscribe(spouse_res => {
      this.items = spouse_res.spouse;
      this.storageService.store(AppConstants.Personspouses, this.items);
      console.log('spouses', this.items);
    },
    error => {
      console.log('get_spouses_error',error);
      //this.loading.dismiss();
    });
  } 


  alertRemoveSpouse(id, name) {
    //let alert = this.alertCtrl.create({
      this.alertCtrl.create({
        header: 'Confirm Alert',
        message: 'Are you sure??? Want to Remove Spouse: '+ name +' ?',
        buttons: [
          {
            text: 'No',
            handler: () => {
              console.log('Let me think');
            }
          },
          {
            text: 'Yes',
            handler: () => {
              this.removeSpouse(id);
            }
          }
        ]
      }).then(res => {
        res.present();
      });
  }

  removeSpouse(id){
    console.log('delete-id', id);
    this.loading.present();
    this.personService.delete_relationship(this.person, 'spouse', id).subscribe(data => {  
      this.get_person();
      this.loading.dismiss();
    },
    error => {
      console.log(error);
      //this.loading.dismiss();
    });
  }




}
